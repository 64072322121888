import React from "react";
import TeamMember from "../components/TeamMember";
import t1 from "../images/team/SP.png";
import t2 from "../images/team/SA.png";
import t3 from "../images/team/GG.png";
import t4 from "../images/team/MA.png";
import t5 from "../images/team/AS.png";
import t6 from "../images/team/VG.png";
import t7 from "../images/team/PS.png";

const clientImage = {
  width: "auto",
  mixBlendMode: "colorBurn",
  maxWidth: "300px",
};

const Team = () => {
  return (
    <div className="mt-8 bg-gray-100" id="portfolio">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Our Team
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
        </div>

        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <div className="flex flex-wrap gap-8 justify-center items-center">
            <TeamMember
              clientImageStyle={clientImage}
              img={t1}
              name={"Sanjeev Pandey"}
              description={`Insolvency & Bankruptcy Expert | Consultant(R&I) DSK Legal,
                CAFRAL, Former DGM & Head of NCLT Division, State Bank of India`}
            />
            <TeamMember
              clientImageStyle={clientImage}
              img={t2}
              name={"Sanjeev Ahuja"}
              description={`Mediator, Zero litigation Advisor, Insolvency Expert,
                Relationship Manager and Conflict Resolution Manager, Critical
                Observation and Analysis is the forte.`}
            />
            <TeamMember
              clientImageStyle={clientImage}
              img={t3}
              name={"Gagan Ghai"}
              description={`FCA, ACMA, ICVS, Strategy Advisor, Valuation expert, Business
                Mentor, Venture catalyst, Practice Innovation, Transaction
                Advisor, Funds Planner`}
            />
            <TeamMember
              clientImageStyle={clientImage}
              img={t4}
              name={"Manoj Arora"}
              description={`Technologist / Agnostic Industry Product Innovator/ Startups,
                Business Recast & Re-engineering & Optimization /
                Sustainability, ESG / Cloud, SaaS, App & Infra Platforms
                Architect/ AI, IoT/ Digital Assets Analyzer`}
            />
            <TeamMember
              clientImageStyle={clientImage}
              img={t5}
              name={"Ajay Shaw"}
              description={`Experienced professional in corporate commercial matters,
                Insolvency and Restructuring, Banking & Finance (including
                Structured Finance and Project Finance), M&A, Infrastructure,
                Energy and Projects, and Policy & Regulatory Advisory.`}
            />
            <TeamMember
              clientImageStyle={clientImage}
              img={t6}
              name={"Vikas Gupta"}
              description={`Qualified CA, DISA and Certified in IFRS. Statutory Audits, Tax
                Audits, Bank Audits, Government Audits, Legal-Due Diligence,
                FEMA Consulting, M & A, Joint Ventures & Company Law Matters.`}
            />
            <TeamMember
              clientImageStyle={clientImage}
              img={t7}
              name={"Prince Sethi"}
              description={`Financial Expert with Thirteen years of overall experience in
                consulting and strategic advisory. Specific areas of operations
                are Insolvency, Bankruptcy and Conflict Resolution.`}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
