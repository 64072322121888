import React from "react";
import img from "../images/bank.png";
import img2 from "../images/restruct.png";
import img3 from "../images/invest.png";
import img4 from "../images/val.png";
import img5 from "../images/res.png";
import img6 from "../images/legal.png";
import img7 from "../images/ren.png";
import img8 from "../images/startup.png";
import img9 from "../images/ma.png";
import img10 from "../images/govt.png";

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            services
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            We focus on ensuring financial and legal literacy through various
            programs and workshops. Our areas of expertise include the
            following:
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Insolvency & Bankruptcy (IBC)
                </h2>
                {/* <p className="text-md font-medium">
                  We specialize in creating and optimizing high-quality, custom
                  websites for businesses and organizations of all sizes.
                  Building mobile-friendly and easy-to-use websites and
                  applications for clients.
                </p> */}
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Banking Solutions (Restructuring, OTS etc)
                </h2>
                {/* <p className="text-md font-medium">
                  We develop high-quality, custom cross-platform mobile
                  applications that are robust and optimized for performance,
                  scalability, and accessibility.
                </p> */}
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Investment Banking (Funding, SME IPO’s, etc)
                </h2>
                {/* <p className="text-md font-medium">
                  We provide domain registration and web hosting services to
                  individuals and organizations to enable them gain visibility
                  in the digital space.
                </p> */}
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Valuations (for M&A, Fund Raise, Dilution, etc)
                </h2>
                {/* <p className="text-md font-medium">
                  Our IT consultations service provides expert guidance and
                  support for businesses and individuals looking to optimize
                  their technology solutions.
                </p> */}
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img5}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Dispute Resolution (Non-Adversarial, Mediation)
                </h2>
                {/* <p className="text-md font-medium">
                  Our IT consultations service provides expert guidance and
                  support for businesses and individuals looking to optimize
                  their technology solutions.
                </p> */}
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img6}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Legal Landscape
                </h2>
                {/* <p className="text-md font-medium">
                  Our IT consultations service provides expert guidance and
                  support for businesses and individuals looking to optimize
                  their technology solutions.
                </p> */}
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img7}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Business Re-engineering
                </h2>
                {/* <p className="text-md font-medium">
                  Our IT consultations service provides expert guidance and
                  support for businesses and individuals looking to optimize
                  their technology solutions.
                </p> */}
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img8}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Start-up Incubation
                </h2>
                {/* <p className="text-md font-medium">
                  Our IT consultations service provides expert guidance and
                  support for businesses and individuals looking to optimize
                  their technology solutions.
                </p> */}
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img9}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Merger & Acquisition
                </h2>
                {/* <p className="text-md font-medium">
                  Our IT consultations service provides expert guidance and
                  support for businesses and individuals looking to optimize
                  their technology solutions.
                </p> */}
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="h-[150px] rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img10}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Environment, Social & Governance
                </h2>
                {/* <p className="text-md font-medium">
                  Our IT consultations service provides expert guidance and
                  support for businesses and individuals looking to optimize
                  their technology solutions.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <h1 className="text-7xl w-full text-orange-500 mt-24 text-center">
            &#x275D; कुशल भारत विकसित भारत &#x275E;
          </h1>
          <h3 className="text-3xl w-full mt-12 text-center">
            Be curious to identify the dots, be creative to join them …….
            Picture will emerge…..
          </h3>
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                  <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                </svg>
              </div>
              <h3
                className="text-3xl  text-blue-900
                            font-bold"
              >
                Promoting <span className="font-black">Entrepreneurship</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We are committed to nurturing a culture of entrepreneurship
                  among India's youth by fostering creativity, innovation, and
                  business acumen. Through mentorship programs, workshops, and
                  outreach initiatives, we inspire the next generation of
                  business leaders to pursue their dreams and turn their ideas
                  into reality.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div>
              <h3 className="text-3xl  text-blue-900 font-bold">
                Empowering <span className="font-black">MSMEs</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We strive to be the cornerstone of support for MSMEs, offering
                  guidance, advocacy, and practical solutions to help them
                  overcome challenges and seize opportunities. From financial
                  management to legal compliance, we provide tailored assistance
                  to MSMEs, enabling them to scale up and thrive in a
                  competitive marketplace.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
