import React from "react";

export default function TeamMember({
  clientImageStyle,
  img,
  name,
  description,
}) {
  return (
    <div
      style={clientImageStyle}
      className="overflow-hidden flex justify-around flex-col gap-5 items-center transition-all ease-in-out opacity-80 hover:opacity-100 w-1/3"
    >
      <img src={img} className="w-[100px] h-[100px]" alt="client" />
      <h2 className="text-lg font-semibold">{name}</h2>
      <p className="text-sm text-gray-500 text-justify">{description}</p>
    </div>
  );
}
