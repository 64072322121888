import React from "react";
import kws from "../images/clients/a1.jpg";
import geps from "../images/clients/a2.jpg";
import protergia from "../images/clients/a3.png";
import c1 from "../images/clients/a4.png";
import c2 from "../images/clients/a5.png";
import c3 from "../images/clients/a6.png";
import c4 from "../images/clients/a7.png";

const clientImage = {
  width: "auto",
  mixBlendMode: "colorBurn",
};

const Clients = () => {
  return (
    <div className="mt-8 bg-gray-100" id="portfolio">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Our Associates
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            Some of our Associates.
          </h2>
        </div>

        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <div className="grid sm:grid-cols-3 lg:grid-cols-7">
            <div
              style={clientImage}
              className="overflow-hidden flex justify-center items-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6"
            >
              <img src={kws} className="w-[200px] h-[50px]" alt="client" />
            </div>

            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center items-center  transition-all ease-in-out opacity-50 hover:opacity-100"
            >
              <img
                src={protergia}
                className="w-[200px] h-[180px]"
                alt="client"
              />
            </div>

            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center items-center  transition-all ease-in-out opacity-50 hover:opacity-100"
            >
              <img src={geps} className="w-[200px] h-[50px]" alt="client" />
            </div>

            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center items-center  transition-all ease-in-out opacity-50 hover:opacity-100"
            >
              <img src={c1} className="w-[200px] h-[50px]" alt="client" />
            </div>
            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center items-center  transition-all ease-in-out opacity-50 hover:opacity-100"
            >
              <img src={c2} className="w-[200px] h-[50px]" alt="client" />
            </div>
            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center items-center  transition-all ease-in-out opacity-50 hover:opacity-100"
            >
              <img src={c3} className="w-[200px] h-[50px]" alt="client" />
            </div>
            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center items-center  transition-all ease-in-out opacity-50 hover:opacity-100"
            >
              <img src={c4} className="w-[200px] h-[50px]" alt="client" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
